var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_c('h3',{staticClass:"float-left"},[_vm._v("Crear Grupo de Atributos")])]),_c('CCardBody',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"contact-form",attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.registrar)}}},[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(" Grupo: ")]),_c('validation-provider',{attrs:{"name":"Grupo","rules":{ required: true },"customMessages":{
                    required: 'Es un campo requerido'
                  }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registro.grupo),expression:"registro.grupo"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.registro.grupo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.registro, "grupo", $event.target.value)}}}),_c('span',{staticStyle:{"color":"red","font-size":"small"}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1)])],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(" Tipo: ")]),_c('validation-provider',{attrs:{"name":"Tipo","rules":{ required: true },"customMessages":{
                    required: 'Es un campo requerido'
                  }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('selectTiposGrupos',{on:{"ActualizandoId":_vm.setTiposGrupos_id}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registro.tipo),expression:"registro.tipo"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.registro.tipo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.registro, "tipo", $event.target.value)}}}),_c('span',{staticStyle:{"color":"red","font-size":"small"}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1)])],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(" Categoria: ")]),_c('validation-provider',{attrs:{"name":"Categoria","rules":{ required: true },"customMessages":{
                    required: 'Es un campo requerido'
                  }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('selectCategoria',{on:{"ActualizandoId":_vm.setCategoria}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registro.eco_categoria_id),expression:"registro.eco_categoria_id"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.registro.eco_categoria_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.registro, "eco_categoria_id", $event.target.value)}}}),_c('span',{staticStyle:{"color":"red","font-size":"small"}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1)])],1),_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_c('span',{staticClass:"cil-save btn-icon mr-2"}),_vm._v(" Guardar ")]),_c('router-link',{attrs:{"to":"/ecoAtributosGrupos/List"}},[_c('CButton',{staticClass:"ml-2",attrs:{"color":"secondary"}},[_c('span',{staticClass:"cil-x-circle btn-icon mr-2"}),_vm._v(" Cancelar ")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }