<template>
  <div>
    <select class="form-control" @change="onChangeValor($event)" v-model="valueIdSelected">
      <option value="Lista">Lista</option>
      <option value="Text">Text</option>
      <option value="Checkbox">Checkbox</option>
    </select>
  </div>
</template>

<script>
export default {
  /* Defino el PROPS que se recibe desde afuera */
  props: {
    tipoId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      valueIdSelected: '',
    };
  },
  watch: {
    /* Observar cambios en el valor del PROPS y cuando ya tenga busco los datos del registro */
    tipoId() {
      this.valueIdSelected = this.tipoId
      return this.valueIdSelected
    }
  },
  methods: {
    onChangeValor(event) {
      this.valueIdSelected = event.target.value;
      this.$emit("ActualizandoId", event.target.value);
    }
  },
  mounted() {
    this.$emit("ActualizandoId", this.valueIdSelected);
  }
};
</script>
